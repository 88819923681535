import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import ogImage from "../images/signup-og-image.jpg";
import SignupForm from "../components/SignupForm";

const SignupPage = ({ location }) => {
  return (
    <Layout
      pageTitle="Sign up for local farm-fresh food deliveries"
      ogUrl="/signup/"
      altOGImage={ogImage}
    >
      <div className="layout-container">
        <div className="user-form-container paper add-padding-2">
          <h3>Create a till account</h3>
          <SignupForm location={location} />
        </div>
        <div className="user-form-info">
          <p>
            Already have an account?{" "}
            <Link to="/login/" state={location.state}>
              Log in
            </Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ({ location }) => {
  return <SignupPage location={location} />;
};
